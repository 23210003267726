<template>
  <main class="error">
    <div class="error-content">
      <div class="icon"><iconic name="exclamation-triangle" /></div>
      <div class="title">{{ $locale["error"][errorCode]["title"] }}</div>
      <div class="desc">{{ $locale["error"][errorCode]["description"] }}</div>
      <div class="reason">{{ $locale["error"][errorCode]["reason"] }}</div>
    </div>
  </main>
</template>

<script>
export default {
  props: ["code"],
  mounted: function() {
    const error = this.$route.params.error;
    if (error === "api" && this.$store.state.api) {
      this.$router.push("/");
    }
  },
  computed: {
    errorCode: function() {
      return this.code || 500;
    },
  },
};
</script>

<style lang="scss">
.error {
  position: relative;
  text-align: center;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-content {
    padding: $mpadding * 2;
    .icon {
      font-size: 320%;
      margin: 0 0 $mpadding 0;
      color: $primary_color;
    }
    .title {
      font-size: 150%;
      font-weight: bold;
    }
    .desc {
      font-size: 110%;
      font-weight: bold;
      margin: $mpadding 0;
    }
  }
}
</style>
